import { Delete, Visibility } from "@material-ui/icons";
import axios from "axios";
import RichTextInput from "ra-input-rich-text";
import React, { useContext } from "react";
import {
    ArrayInput, AutocompleteArrayInput,
    BooleanInput,
    Button, Datagrid,
    DeleteWithConfirmButton,
    Edit,
    FormDataConsumer, FormTab, ReferenceInput, ReferenceManyField,
    SaveButton,
    SimpleFormIterator,
    TabbedForm, TextField, TextInput,
    Toolbar,
    TopToolbar,
    useRedirect, useRefresh
} from "react-admin";
import AddNewAttachmentButton from "../../components/add-new-attachment-button";
import AttachmentDeleteButton from "../../components/attachment-delete-button";
import { Spacer } from "../../components/spacer";
import { VideoUrlInput } from "../../components/video-url-input";
import { ViewFileButton } from "../../components/view-file-button";
import { AppContext } from "../../providers/app-context-provider/app.context";
import cookiesProvider from "../../providers/cookies-provider";
import { uploadFileTransform } from "../../utils/upload-file-transform";
import { getApiUrl } from "../../utils/urls";
import { videoIdExtractorTransform } from "../../utils/video-id-extractor-transform";
import SchoolModelActivityEditAddPartyType from "./school-model-activities-add-party-type";


const RemoveRelated = ({ model_activity, record, refresh, resource, ...props }) => {

    const apiUrl = getApiUrl()
    const token = cookiesProvider.getToken()

    const handleClick = async () => {
        axios.delete(
            `${apiUrl}/school-model-activities/${model_activity}/${resource}/${record.id}`,
            { headers: { Authorization: `Bearer ${token}` } }
        ).then(() => { refresh() })
    }

    return (
        <Button label={'Remover'} onClick={handleClick}>
            <Delete />
        </Button>
    )
}


const SchoolModelActivityEdit = (props) => {

    const redirect = useRedirect()
    const refresh = useRefresh();

    const { tab } = props.history.location.state ? props.history.location.state : { tab: false };


    const ToolbarEdit = ({ basePath, data }) => (
        <TopToolbar>
            <Button variant="contained" label={'Dashboard'} onClick={() => redirect(`/model-activity-dashboard/${data.id}`)}>
                <Visibility />
            </Button>
        </TopToolbar>
    )

    const transform = async (data) => {
        let newData = data;
        data = videoIdExtractorTransform(newData)
        data = await uploadFileTransform(newData, 'school-model-activity-guidelines', 'guideline_files')
        return { ...newData }
    }

    const CustomFormToolbar = props => {

        const context = useContext(AppContext)
        const profile = context.currentUser.profiles[0];
        const isAdmin = context.superAdmin || profile.name === 'Administrador' || profile.name === 'Coordenador'

        return (
            <Toolbar {...props}>
                <SaveButton disabled={props.pristine} />
                <Spacer />
                {isAdmin && <DeleteWithConfirmButton confirmTitle="Deseja excluir esta atividade? A ação não poderá ser desfeita." />}
            </Toolbar>
        )
    }

    return (
        <Edit
            mutationMode="pessimistic"
            actions={<ToolbarEdit />}
            transform={transform}
            onSuccess={({ data }) => {
                redirect(`/model-activity-dashboard/${data.id}`)
            }}
            {...props}
        >
            <TabbedForm toolbar={<CustomFormToolbar />}>

                {!tab &&
                    <FormTab label={'Dados Gerais'}>
                        <TextInput source='name' label='Título' fullWidth />
                        <TextInput source='author' label='Autor' fullWidth />
                        <ReferenceInput source='themes' label='Ramos do direito' perPage={100} reference="themes" sort={{ field: 'name', order: 'ASC' }} fullWidth>
                            <AutocompleteArrayInput
                                optionText="name"
                                optionValue="id"
                                suppressRefError
                                options={{
                                    suggestionsContainerProps: {
                                        modifiers: {
                                            computeStyle: {
                                                enabled: true,
                                                gpuAcceleration: false,
                                            },
                                            applyStyle: {
                                                enabled: true,
                                            },
                                        },
                                    }
                                }}
                                fullWidth
                            />
                        </ReferenceInput>

                        <FormDataConsumer>
                            {
                                ({ formData, record, ...rest }) => {
                                    return (
                                        record.proposal && record.proposal?.trim() !== ''
                                        && record.prerequisites && record.prerequisites?.trim() !== ''
                                        && record.details && record.details?.trim() !== ''
                                        && record.party_types?.length > 0
                                        && record.tasks.length > 0
                                    ) && <BooleanInput source="published" label="Publicado?" />
                                }
                            }
                        </FormDataConsumer>

                    </FormTab>}

                {(!tab || tab === 'proposal') &&
                    <FormTab label={'Proposta de ensino'} path="proposal">
                        <RichTextInput source='proposal' label='Proposta de ensino/aprendizagem' />
                    </FormTab>}

                {(!tab || tab === 'prerequisites') &&
                    <FormTab label={'Pré-requisitos do aluno'} path="prerequisites">
                        <RichTextInput source='prerequisites' label='Pré-requisitos do aluno' />
                    </FormTab>}

                {(!tab || tab === 'guidelines') &&
                    <FormTab label={'Espelho de correção'} path="guidelines">
                        <RichTextInput source='guidelines' label='Orientações' />
                        <ReferenceManyField fullWidth reference='attachments' resource='model-activity-guidelines' target='modelActivityGuidelineId' label={'Anexos'}>
                            <Datagrid>
                                <TextField source={'name'} label={'Nome'} />
                                <ViewFileButton />
                                <FormDataConsumer>
                                    {({ formData, record }) =>
                                        <AttachmentDeleteButton path='school-model-activity-guidelines' resource={'attachments'} relatedId={formData.id} record={record} undoable={false} redirect={false} />
                                    }
                                </FormDataConsumer>
                            </Datagrid>
                        </ReferenceManyField>
                        <FormDataConsumer>
                            {({ record }) =>
                                <AddNewAttachmentButton record={record} resource={'school-model-activity-guidelines'} redirectTo={`/school-model-activities/${record.id}/guidelines`} fileSize={25000000} multiple={true} />
                            }
                        </FormDataConsumer>
                    </FormTab>}

                {(!tab || tab === 'details') &&
                    <FormTab label={'Orientações gerais'} path="details">
                        <RichTextInput source='details' label='Orientações gerais' />
                        <ArrayInput source="links" label="Vídeos" variant="standard">
                            <SimpleFormIterator>
                                <TextInput source="title" label="Título" fullWidth />
                                <VideoUrlInput source="url" label="URL" fullWidth />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </FormTab>}

                {(!tab || tab === 'parties') &&
                    <FormTab label={'Sujeitos processuais'} path="parties">
                        <ReferenceManyField fullWidth reference="party-types" sort={{ field: 'party-type.name', order: 'ASC' }} target="model_activity.id" label={'Sujeitos processuais representados pelo aluno'}>
                            <Datagrid>
                                <TextField source='name' label={'Nome'} />
                                <Spacer />
                                <FormDataConsumer>
                                    {
                                        ({ formData, record, ...rest }) => {
                                            return <RemoveRelated resource={'partyType'} record={record} model_activity={formData.id} refresh={refresh} />
                                        }
                                    }
                                </FormDataConsumer>
                            </Datagrid>
                        </ReferenceManyField>
                        <FormDataConsumer>
                            {
                                ({ formData, record, ...rest }) => {
                                    return <SchoolModelActivityEditAddPartyType record={record} refresh={refresh} />
                                }
                            }
                        </FormDataConsumer>
                    </FormTab>}
                {(!tab || tab === 'attachments') &&
                    <FormTab label="Anexos" path="attachments">
                        <ReferenceManyField fullWidth reference='attachments' target='schoolModelActivityId' label={'Anexos'}>
                            <Datagrid>
                                <TextField source={'name'} label={'Nome'} />
                                <ViewFileButton />
                                <FormDataConsumer>
                                    {({ formData, record }) =>
                                        <AttachmentDeleteButton path='school-model-activities' resource={'attachments'} relatedId={formData.id} record={record} undoable={false} redirect={false} />
                                    }
                                </FormDataConsumer>
                            </Datagrid>
                        </ReferenceManyField>
                        <AddNewAttachmentButton fileSize={25000000} multiple={true} />
                    </FormTab>}
            </TabbedForm>
        </Edit >
    )
}

export default SchoolModelActivityEdit 