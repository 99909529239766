import { Visibility } from "@material-ui/icons";
import RichTextInput from "ra-input-rich-text";
import React, { useState } from "react";
import {
    ArrayInput, BooleanInput, Button, CheckboxGroupInput, Datagrid,
    DateTimeInput,
    Edit,
    FormDataConsumer, FormTab, ReferenceInput, ReferenceManyField, SelectInput, SimpleFormIterator,
    TabbedForm, TextField, TextInput, TopToolbar, required, useNotify, useRedirect
} from "react-admin";
import AddNewAttachmentButton from "../../components/add-new-attachment-button";
import { AIEvaluationTest } from "../../components/ai-evaluation-test";
import AttachmentDeleteButton from "../../components/attachment-delete-button";
import HideableFormTab from "../../components/hideable-form-tab";
import { VideoUrlInput } from "../../components/video-url-input";
import { ViewFileButton } from "../../components/view-file-button";
import { videoIdExtractorTransform } from "../../utils/video-id-extractor-transform";


const SchoolActivityTaskEdit = (props) => {

    const redirect = useRedirect();
    const notify = useNotify()

    const [showTab, setShowTab] = useState(false)

    const compare = (record) => record.is_ai_assisted || showTab
    const { tab, redirectTo } = props.history.location.state ? props.history.location.state : { tab: false, redirectTo: 'edit' };

    const ToolbarEdit = ({ basePath, data }) => (
        <TopToolbar>
            <Button label={'Dashboard'} onClick={() => redirect(`/school-activity-dashboard/${data.school_activity.id}`)}>
                <Visibility />
            </Button>
        </TopToolbar>
    )

    const onSuccess = ({ data }) => {
        notify(`Item atualizado com sucesso`);
        redirect(`/school-activity-dashboard/${data.school_activity.id}`)
    };

    const CustomTitle = ({ record }) => {
        return <span> {`Etapa matriz - ${record.name}`} </span>
    }

    return (
        <Edit
            mutationMode="pessimistic"
            actions={<ToolbarEdit />}
            title={<CustomTitle />}
            onSuccess={onSuccess}
            transform={videoIdExtractorTransform}
            {...props}
        >
            <TabbedForm warnWhenUnsavedChanges redirect={redirectTo}>
                {!tab &&
                    <FormTab label="Dados Principais">
                        <TextInput source="name" label="Título" variant="standard" fullWidth />
                        <FormDataConsumer>
                            {({ formData, record, ...rest }) => {
                                return <CheckboxGroupInput
                                    source="roles"
                                    label="Partes que podem ver esta etapa"
                                    variant="filled"
                                    choices={record?.school_activity?.party_types?.map(item => ({ ...item, id: item.id.toString() }))}
                                    optionText="name"
                                    optionValue={'id'}
                                    fullWidth
                                    parse={value =>
                                        value && value.map(v => ({ id: v }))
                                    }
                                    format={value => value && value.map(v => v.id)}
                                />
                            }}
                        </FormDataConsumer>
                        <ReferenceInput source="guideline_type.id" reference="guideline-types" label="Orientação padrão" fullWidth>
                            <SelectInput source="name" optionText={"name"} fullWidth />
                        </ReferenceInput>

                        <BooleanInput fullWidth source="is_ai_assisted" label="Utiliza a correção por IA do Juristta" defaultValue={false} onChange={(e) => setShowTab(e)} />
                        <BooleanInput fullWidth source="requires_grade" label="Requer correção" defaultValue={true} />
                        <BooleanInput fullWidth source="video" label="Audiência de vídeo" />
                        <FormDataConsumer>
                            {({ formData, record, ...rest }) => {
                                return formData.video && <>
                                    <TextInput source="video_url" label="Url da audiência de vídeo" variant="filled" validate={[required()]} fullWidth />
                                    <DateTimeInput source="video_date" label={'Data da audiência'} validate={[required()]} fullWidth />
                                </>
                            }}
                        </FormDataConsumer>

                        <BooleanInput fullWidth source="metaverse" label="Audiência no metaverso" />
                        <FormDataConsumer>
                            {({ formData, record, ...rest }) => {
                                return formData.metaverse && <>
                                    <DateTimeInput source="metaverse_video_date" label={'Data da audiência'} validate={[required()]} fullWidth />
                                </>
                            }}
                        </FormDataConsumer>

                        <BooleanInput fullWidth source="requires_tpu" label="Requer código TPU" />
                        <ArrayInput
                            source="checklist"
                            label="Checklist de correção"
                            variant="standard"
                        >
                            <SimpleFormIterator>
                                <TextInput source="description" label="Descrição" fullWidth />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </FormTab>
                }
                {(!tab || tab === 'details') &&
                    <FormTab label="Instruções" path="details">
                        <RichTextInput source="details" label="Instruções" />
                        <ArrayInput source="links" label="Vídeos" variant="standard">
                            <SimpleFormIterator>
                                <TextInput source="title" label="Título" fullWidth />
                                <VideoUrlInput source="url" label="URL" fullWidth />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </FormTab>
                }
                {(!tab || tab === 'attachments') &&
                    <FormTab label="Anexos" path="attachments">
                        <ReferenceManyField fullWidth reference='attachments' target='schoolActivityTaskId' label={'Anexos'}>
                            <Datagrid>
                                <TextField source={'name'} label={'Nome'} />
                                <ViewFileButton />
                                <FormDataConsumer>
                                    {({ formData, record }) =>
                                        <AttachmentDeleteButton path='school-activity-tasks' resource={'attachments'} relatedId={formData.id} record={record} undoable={false} redirect={false} />
                                    }
                                </FormDataConsumer>
                            </Datagrid>
                        </ReferenceManyField>
                        <AddNewAttachmentButton multiple={true} small={true} />
                    </FormTab>
                }

                {(!tab || tab === 'guidelines') &&
                    <HideableFormTab label="Espelho de correção" path="guidelines" comparison={compare}>
                        <RichTextInput source="ai_guidelines" label="Espelho de correção da movimentação" />
                        <AIEvaluationTest />
                    </HideableFormTab>
                }

                {(!tab || tab === 'document') &&
                    <HideableFormTab label="Autos do processo" path="document" comparison={compare}>
                        <ReferenceManyField fullWidth reference='attachments' target='schoolActivityTaskAiGuidelineId' label={'Anexos'}>
                            <Datagrid>
                                <TextField source={'name'} label={'Nome'} />
                                <ViewFileButton />
                                <FormDataConsumer>
                                    {({ formData, record }) =>
                                        <AttachmentDeleteButton path='school-activity-tasks-ai-guidelines' resource={'attachments'} relatedId={formData.id} record={record} undoable={false} redirect={false} />
                                    }
                                </FormDataConsumer>
                            </Datagrid>
                        </ReferenceManyField>
                        <AddNewAttachmentButton
                            resource={'school-activity-tasks-ai-guidelines'}
                            fileSize={25000000}
                            multiple={true} />
                    </HideableFormTab>
                }
            </TabbedForm>
        </Edit >
    )
}

export default SchoolActivityTaskEdit 