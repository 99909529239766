import { Avatar, Box, Dialog, DialogContent, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, ListSubheader, Button as MuiButton, Typography } from "@material-ui/core";
import { Delete, Person, RateReview } from "@material-ui/icons";
import RichTextInput from "ra-input-rich-text";
import React, { useState } from "react";
import { Button, CheckboxGroupInput, SaveButton, SimpleForm, TextInput, Toolbar, useNotify, useRefresh } from "react-admin";
import dataProvider from "../../../providers/data-provider";
import { ListAttachments } from "../../list-attachments/list-attachments.component";
import { Spacer } from "../../spacer";
import { DangerousHTMLDiv } from "../../dangerousHTMLDiv";
import { orderByObjectName } from "../../../utils/functions";
import MarkdownView from "react-showdown";
const EvaluateButton = ({ record, isAiFeedback = false, ...props }) => {

    const [dialogOpen, setDialogOpen] = useState(false)
    const fields = {};

    const notify = useNotify()
    const refresh = useRefresh()
    const evaluated = record.evaluated

    const openDialog = () => setDialogOpen(true)
    const closeDialog = () => setDialogOpen(false);

    const handleSubmit = async (values, appropriate) => {
        dataProvider.post('school-activity-task-completions', `${record.id}/evaluate`, { ...values, appropriate })
            .then(response => {
                if (response.status === 500) {
                    notify('Erro', 'error')
                } else {
                    notify('Avaliação realizada com sucesso', 'success')
                    refresh()
                }
            })
            .catch((error) => {
                notify(error, 'error')
            });
        setDialogOpen(false);
    }

    const handleUndo = async () => {
        dataProvider.delete('school-activity-task-completions', { id: `${record.id}/clearEvaluate` })
            .then(response => {
                if (response.status === 500) {
                    notify('Erro', 'error')
                } else {
                    notify('Correção removida com sucesso', 'success')
                    refresh()
                }
            })
            .catch((error) => {
                notify(error, 'error')
            });
        setDialogOpen(false);
    }

    const ToolbarForm = (props) => {
        return <Toolbar {...props} >
            <MuiButton
                style={{ marginLeft: '5px' }}
                variant={'contained'}
                onClick={closeDialog}
                size={'small'}>
                Cancelar
            </MuiButton>
            <Spacer />
            {!isAiFeedback && <>
                <MuiButton
                    style={{ marginRight: '5px' }}
                    variant={'contained'}
                    startIcon={<Delete />}
                    disabled={!evaluated}
                    onClick={handleUndo}
                    size={'small'}>
                    Desfazer avaliação
                </MuiButton>
                <SaveButton
                    style={{ marginRight: '5px' }}
                    label="Salvar e apropriar aos autos"
                    submitOnEnter={false}
                    onSave={(values) => handleSubmit(values, true)}
                    size={'small'}
                />
                <SaveButton
                    label="Salvar"
                    submitOnEnter={false}
                    onSave={(values) => handleSubmit(values, false)}
                    size={'small'}
                />
            </>
            }
        </Toolbar>
    }

    return (
        <>
            {
                <Button
                    startIcon={<RateReview />}
                    size='small'
                    label={evaluated ? "Revisar avaliação" : "Avaliar"}
                    onClick={openDialog}
                />
            }
            <Dialog open={dialogOpen} onClose={closeDialog} maxWidth={'md'} fullWidth>
                <DialogTitle> {evaluated ? "Revisar avaliação" : "Avaliar movimentação"} </DialogTitle>
                <DialogContent dividers>
                    <List dense={true} style={{ display: "flex", flexFlow: 'wrap', justifyContent: 'space-between' }} subheader={<ListSubheader style={{ width: '100%' }} disableSticky>Alunos</ListSubheader>}>
                        {record.team.team_memberships?.filter(el => record?.role.id === el.role.id).sort((a, b) => orderByObjectName(a.student, b.student)).map((user, index) => {
                            return (
                                <ListItem key={index} style={{ maxWidth: '30%' }}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Person />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={user.student.name}
                                        secondary={user.student.email}
                                    />
                                </ListItem>
                            )
                        })}
                    </List>
                    <Box paddingLeft={'16px'}>
                        <Typography color="textSecondary" style={{
                            fontSize: '0.875rem',
                            fontFamily: 'Roboto',
                            fontWeight: 500,
                            lineHeight: '48px'
                        }}>
                            Observações do aluno
                        </Typography>
                        <DangerousHTMLDiv html={record.text} />
                    </Box>
                    <List subheader={<ListSubheader disableSticky>Anexos</ListSubheader>} >
                        <ListAttachments
                            attachments={record.attachments}
                            enableDelete={false}
                            relatedResource={'school-activity-task-completions'}
                        />
                    </List>
                    <Typography variant="body2" color="textSecondary" style={{ fontWeight: 'bold', paddingLeft: '16px' }}>Feedback da IA</Typography>
                    {isAiFeedback && <Typography style={{ padding: '8px 16px 8px 16px' }}><MarkdownView flavor="github" markdown={record.feedback} /></Typography>}
                    <SimpleForm toolbar={<ToolbarForm />} initialValues={fields}>
                        {
                            record.task.checklist?.length > 0 && (
                                <CheckboxGroupInput disabled={isAiFeedback} row={false} source="checklist" choices={record.task.checklist.map((el, idx) => ({ id: idx, name: el.description }))} />
                            )
                        }
                        <TextInput source='grade' label='Nota' disabled={isAiFeedback} fullWidth />
                        {!isAiFeedback && <RichTextInput source='feedback' label='Observações' fullWidth />}
                    </SimpleForm>
                </DialogContent>
            </Dialog >
        </>
    )
}


export default EvaluateButton